import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableRowNode } from "@lexical/table";
import { LexicalEditor } from "lexical";
import React, { useMemo } from "react";

import { RichTextDocument } from "../../../../../shared/block-editor-data/types";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import AutoFocusPlugin from "./AutoFocusPlugin/AutoFocusPlugin";
import DefaultTabbedPlugin from "./DefaultTabbedPlugin/DefaultTabbedPlugin";
import ImportHtmlPlugin, { initialContent } from "./ImportHtmlPlugin";
import NormalisationPlugin from "./NormalisationPlugin";
import Toolbar from "./Toolbar/BlockEditorToolbar";
import { FileUploadNode } from "./blocks/FileUploadBlock/FileUploadNode";
import FileUploadPlugin from "./blocks/FileUploadBlock/FileUploadPlugin";
import { HtmlSnippetNode } from "./blocks/HtmlSnippetBlock/HtmlSnippetNode";
import HtmlSnippetPlugin from "./blocks/HtmlSnippetBlock/HtmlSnippetPlugin";
import { ImageNode } from "./blocks/ImageBlock/ImageNode";
import ImagePlugin from "./blocks/ImageBlock/ImagePlugin";
import { UploadProvider } from "./blocks/ImageBlock/useUpload";
import { NestedEditorContext } from "./blocks/NestedContext";
import inDetailChartPlugin from "./blocks/chart/in-detail";
import overTimeChartPlugin from "./blocks/chart/over-time";
import { TableNode } from "./blocks/table/TableNode";
import { TablePlugin } from "./blocks/table/TablePlugin";
import styles from "./styles.module.scss";
import theme from "./text-styling/theme";

export default function BlockEditor({
  defaultValue,
  onChange,
  autoFocus,
  main,
  className,
  editorClassName,
  hideInsightsControls,
}: {
  /** Either a RichTextDocument made in Lexical, null for the default empty document, or an HTML snippet as a string to be converted */
  defaultValue?: RichTextDocument | string | null;
  onChange: (value: RichTextDocument) => void;
  autoFocus?: boolean;
  /** true if this is the most important element on the page, eg, a document editor screen. false if it's one of several */
  main?: boolean;
  className?: string;
  editorClassName?: string;
  /** Disable charts and tables based on Analytics */
  hideInsightsControls?: boolean;
}) {
  const toast = useToast();

  const config = useMemo(
    () => ({
      namespace: "CSBlockEditor",
      theme,
      onError: (error: Error, editor: LexicalEditor) => toast.error(error ?? "Error", { title: "Error editing text" }),
      editorState: initialContent(defaultValue),
      nodes: [
        ListNode,
        ListItemNode,
        LinkNode,
        HeadingNode,
        inDetailChartPlugin.node,
        overTimeChartPlugin.node,
        TableCellNode,
        TableRowNode,
        TableNode,
        ImageNode,
        FileUploadNode,
        HtmlSnippetNode,
      ],
    }),
    [defaultValue, toast],
  );

  const nestedEditorConfig = useMemo(
    () => ({
      namespace: "nestedEditor",
      nodes: [LinkNode],
      onError: (error: Error, editor: LexicalEditor) => toast.error(error ?? "Error", { title: "Error editing text" }),
      theme,
    }),
    [toast],
  );

  return (
    <div className={`${main ? styles.MainElement : ""} ${className ?? ""}`}>
      <LexicalComposer initialConfig={config}>
        <NestedEditorContext>
          <UploadProvider>
            <Toolbar hideInsightsControls={!!hideInsightsControls} />
            {typeof defaultValue === "string" ? <ImportHtmlPlugin html={defaultValue} /> : null}
            <RichTextPlugin
              contentEditable={<ContentEditable className={`${styles.ContentEditable} ${editorClassName ?? ""}`} />}
              placeholder=""
            />
            <ImagePlugin nestedEditorConfig={nestedEditorConfig}>
              <AutoFocusPlugin />
              <RichTextPlugin
                contentEditable={
                  <ContentEditable className={`${styles.NestedEditor__contentEditable} ${editorClassName ?? ""}`} />
                }
                placeholder=""
              />
            </ImagePlugin>
            <FileUploadPlugin />
            <HtmlSnippetPlugin />
            <ListPlugin />
            <LinkPlugin />
            {hideInsightsControls ? "" : <inDetailChartPlugin.Plugin />}
            {hideInsightsControls ? "" : <overTimeChartPlugin.Plugin />}
            <NormalisationPlugin onChange={onChange} />
            <HistoryPlugin />
            {
              // The LexicalComposer component doesn't accept null as a child so use an empty string
              autoFocus ? <AutoFocusPlugin /> : ""
            }
            <TablePlugin nestedEditorConfig={nestedEditorConfig}>
              <AutoFocusPlugin />

              <RichTextPlugin
                contentEditable={
                  <ContentEditable className={`${styles.NestedEditor__contentEditable} ${editorClassName ?? ""}`} />
                }
                placeholder=""
              />
            </TablePlugin>
            <DefaultTabbedPlugin />
          </UploadProvider>
        </NestedEditorContext>
      </LexicalComposer>
    </div>
  );
}
