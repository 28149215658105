import React, { useCallback } from "react";

import inDetailPlugin from ".";
import { InDetailChartBlockOptions } from "../../../../../../../../shared/block-editor-data/chart-block-types";
import { useCommand } from "../../../hooks";
import EditInDetailChartDialog from "./EditInDetailChartDialog";

export default function InsertInDetailChartDialog({ close }: { close: () => void }) {
  const insertInDetailCommand = useCommand(inDetailPlugin.commands.insert);

  const insertInDetailChart = useCallback(
    (options: InDetailChartBlockOptions) => {
      insertInDetailCommand(options);
      close();
    },
    [close, insertInDetailCommand],
  );

  return <EditInDetailChartDialog onOk={insertInDetailChart} onCancel={close} />;
}
