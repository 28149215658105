import React, { useCallback } from "react";

import overTimePlugin from ".";
import { OverTimeChartBlockOptions } from "../../../../../../../../shared/block-editor-data/chart-block-types";
import { useCommand } from "../../../hooks";
import EditOverTimeChartDialog from "./EditOverTimeChartDialog";

export default function InsertOverTimeChartDialog({ close }: { close: () => void }) {
  const insertOverTimeCommand = useCommand(overTimePlugin.commands.insert);

  const insertOverTimeChart = useCallback(
    (options: OverTimeChartBlockOptions) => {
      insertOverTimeCommand(options);
      close();
    },
    [close, insertOverTimeCommand],
  );

  return <EditOverTimeChartDialog onOk={insertOverTimeChart} onCancel={close} />;
}
