// This is different from a Select — this performs actions whereas a Select chooses a value.
import React, {
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import Button, { ButtonSizeProps, GlobalButtonProps } from "../../../../../shared/components/design-system/Button";
import Icon, { IconSlug } from "../../../../../shared/components/design-system/Icon";
import { Translatable, Translation } from "../../../../../shared/components/translation";
import useGeneratedId from "../../../../../shared/hooks/useGeneratedId";
import { useOpenableWithTracking } from "../../../hooks/useBoolean";
import Checkbox from "../Checkbox";
import { ModalPopup, PopupAxisPosition } from "../Popup";
import styles from "./styles.module.scss";

const POSITIONS = [
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchStart, y: PopupAxisPosition.Before },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.After },
  { x: PopupAxisPosition.MatchEnd, y: PopupAxisPosition.Before },
];

interface DropdownItemDefinition {
  id: string;
}

interface Payload {
  close: () => void;
  registerItem: (item: DropdownItemDefinition) => void;
  unregisterItem: (id: string) => void;
}

const context = createContext<Payload | null>(null);

/** A button that displays a popup menu containing more options. The only children you should use in this component are <DropdownItem /> and <hr />
 *
 * This only supports being an IconButton, but it probably should support being other types of button in the future.
 */
export function DropdownButton({
  children,
  trackingName,
  alwaysShow,
  ...buttonProps
}: PropsWithChildren<{
  trackingName: string;
  /** Instead of hiding the menu when it's empty, show it as disabled */
  alwaysShow?: boolean;
}> &
  Omit<GlobalButtonProps, "onClick"> &
  ButtonSizeProps) {
  const { isOpen, open, close } = useOpenableWithTracking(trackingName);
  const [items, setItems] = useState<DropdownItemDefinition[]>([]);

  const payload = useMemo<Payload>(
    () => ({
      registerItem(item: DropdownItemDefinition) {
        setItems((items) => [...items, item]);
      },
      unregisterItem(id: string) {
        setItems((items) => items.filter((item) => item.id !== id));
      },
      close,
    }),
    [close],
  );

  const menuRootRef = useRef<HTMLButtonElement>(null);

  return (
    <context.Provider value={payload}>
      {items.length || alwaysShow ? (
        <Button {...buttonProps} disabled={!items.length} onClick={open} ref={menuRootRef} />
      ) : null}
      <Dropdown menuRootRef={menuRootRef} label={buttonProps.label} isOpen={isOpen}>
        {children}
      </Dropdown>
    </context.Provider>
  );
}

function Dropdown({
  children,
  label,
  isOpen,
  menuRootRef,
}: PropsWithChildren<{
  label: Translatable;
  isOpen: boolean;
  menuRootRef: RefObject<HTMLButtonElement>;
}>) {
  const { close } = useContext(context)!;

  useEffect(() => {
    window.addEventListener("blur", close);
    return () => window.removeEventListener("blur", close);
  }, [close]);

  return (
    <ModalPopup positions={POSITIONS} title={label} source={menuRootRef} shown={isOpen} onClose={close}>
      <div className={styles.Dropdown}>{children}</div>
    </ModalPopup>
  );
}

export function DropdownCheckbox({
  label,
  checked,
  onChange,
}: {
  label: Translatable;
  checked: boolean;
  onChange: (value: boolean) => void;
}) {
  const payload = useContext(context);
  if (!payload) throw new Error("You can only use DropdownCheckbox within a Dropdown");
  const { registerItem, unregisterItem } = payload;
  const itemId = useGeneratedId();

  useEffect(() => {
    registerItem({
      id: itemId,
    });
    return () => {
      unregisterItem(itemId);
    };
  }, [registerItem, unregisterItem, itemId]);

  return (
    <div className={styles.DropdownCheckboxBackground}>
      <Checkbox
        size="small"
        onChange={onChange}
        checked={checked}
        label={label}
        className={styles.DropdownCheckboxLabel}
      />
    </div>
  );
}

export function DropdownItem({
  icon,
  label,
  onClick,
}: {
  icon?: IconSlug | null;
  label: Translatable;
  onClick: () => void;
}) {
  const payload = useContext(context);
  if (!payload) throw new Error("You can only use DropdownItem within a Dropdown");
  const { close, registerItem, unregisterItem } = payload;

  const itemId = useGeneratedId();

  const internalUseClick = useCallback(() => {
    onClick();
    close();
  }, [onClick, close]);

  useEffect(() => {
    registerItem({
      id: itemId,
    });
    return () => {
      unregisterItem(itemId);
    };
  }, [icon, registerItem, unregisterItem, itemId]);

  return (
    <button type="button" onClick={internalUseClick} className={styles.Item}>
      {icon ? <Icon icon={icon} className={styles.Icon} /> : null}
      <Translation props={label} />
    </button>
  );
}

export function DropdownInternalLink({
  icon,
  label,
  to,
  onClick,
}: {
  icon?: IconSlug | null;
  label: Translatable;
  to: string;
  onClick?: () => void;
}) {
  const payload = useContext(context);
  if (!payload) throw new Error("You can only use DropdownInternalLink within a Dropdown");
  const { close, registerItem, unregisterItem } = payload;

  const itemId = useGeneratedId();

  const internalUseClick = useCallback(() => {
    onClick?.();
    close();
  }, [onClick, close]);

  useEffect(() => {
    registerItem({
      id: itemId,
    });
    return () => {
      unregisterItem(itemId);
    };
  }, [icon, registerItem, unregisterItem, itemId]);

  return (
    <Link to={to} onClick={internalUseClick} className={styles.Item}>
      {icon ? <Icon icon={icon} className={styles.Icon} /> : null}
      <Translation props={label} />
    </Link>
  );
}

export function DropdownExternalLink({
  icon,
  label,
  to,
  onClick,
}: {
  icon?: IconSlug | null;
  label: Translatable;
  to: string;
  onClick?: () => void;
}) {
  const payload = useContext(context);
  if (!payload) throw new Error("You can only use DropdownExternalLink within a Dropdown");
  const { close, registerItem, unregisterItem } = payload;

  const itemId = useGeneratedId();

  const internalUseClick = useCallback(() => {
    onClick?.();
    close();
  }, [onClick, close]);

  useEffect(() => {
    registerItem({
      id: itemId,
    });
    return () => {
      unregisterItem(itemId);
    };
  }, [icon, registerItem, unregisterItem, itemId]);

  return (
    <a href={to} onClick={internalUseClick} className={styles.Item} target="_blank" rel="noreferrer noopener">
      <Icon icon={icon ?? "arrowUpRightFromSquare"} className={styles.Icon} />
      <Translation props={label} />
    </a>
  );
}
