import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, REDO_COMMAND, UNDO_COMMAND } from "lexical";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useOpenableWithTracking } from "../../../../hooks/useBoolean";
import { DropdownButton, DropdownItem } from "../../Dropdown";
import useSelect, { SelectProps } from "../../RadioGroup/useSelect";
import InsertFileUploadDialog from "../blocks/FileUploadBlock/InsertFileUploadDialog";
import InsertSnippetModal from "../blocks/HtmlSnippetBlock/InsertHtmlSnippetModal";
import InsertImageDialog from "../blocks/ImageBlock/InsertImageDialog";
import InsertInDetailChartDialog from "../blocks/chart/in-detail/InsertInDetailChartDialog";
import InsertOverTimeChartDialog from "../blocks/chart/over-time/InsertOverTimeChartDialog";
import InsertTableDialog from "../blocks/table/InsertTableDialog";
import { useIsRootEditor } from "../hooks";
import CommandButton from "./CommandButton";
import FormattingButton from "./FormattingButton";
import LinkButton from "./LinkButton";
import StylingDropdown from "./StylingDropdown";
import styles from "./styles.module.scss";

enum ChartType {
  InDetail = "in-detail",
  OverTime = "overtime",
}

const CHART_OPTIONS: SelectProps<ChartType> = {
  title: "Insert analytics data",
  label: "What sort of data would you like to insert?",
  okCaption: "Next",
  cancelCaption: "Cancel",
  options: [
    {
      id: ChartType.InDetail,
      title: "In detail",
      body: "Split by report question and filter to see a detailed breakdown of your reporting data",
    },
    {
      id: ChartType.OverTime,
      title: "Over time",
      body: "Split by time and filter to see trends in your reporting data over time",
    },
  ],
  trackingId: "insert analytics data",
  defaultValue: ChartType.InDetail,
};

export default function BlockEditorToolbar({
  hideInsightsControls,
}: {
  /** Disable charts and tables based on Analytics */
  hideInsightsControls: boolean;
}) {
  return (
    <div className={styles.Toolbar}>
      <div className={styles.Group}>
        <CommandButton
          command={UNDO_COMMAND}
          canCommand={CAN_UNDO_COMMAND}
          payload={undefined}
          icon="undo"
          label="Undo"
        />
        <CommandButton
          command={REDO_COMMAND}
          canCommand={CAN_REDO_COMMAND}
          payload={undefined}
          icon="redo"
          label="Redo"
        />
      </div>

      <div className={styles.Group}>
        <StylingDropdown />
      </div>

      <div className={styles.Group}>
        <FormattingButton format="bold" icon="bold" label="Bold" />
        <FormattingButton format="italic" icon="italic" label="Italic" />
        <FormattingButton format="underline" icon="underline" label="Underline" />
        <LinkButton />
      </div>

      <div className={styles.Group}>
        <InsertMenu hideInsightsControls={hideInsightsControls} />
      </div>
    </div>
  );
}

export function InsertMenu({ hideInsightsControls = true }: { hideInsightsControls?: boolean }) {
  const { siteId } = useParams<"siteId" | "organisationId">();
  const { open: openImage, isOpen: isImageOpen, close: closeImage } = useOpenableWithTracking("Insert image modal");
  const { open: openFile, isOpen: isFileOpen, close: closeFile } = useOpenableWithTracking("Insert file modal");
  const { open: openTable, isOpen: isTableOpen, close: closeTable } = useOpenableWithTracking("Insert table modal");
  const { close: closeChart } = useOpenableWithTracking("Insert chart modal");
  const {
    open: openSnippet,
    isOpen: isSnippetOpen,
    close: closeSnippet,
  } = useOpenableWithTracking("Insert snippet modal");

  const [openChartDialog, setOpenChartDialog] = useState<ChartType | null>(null);
  const selectChart = useSelect();

  const onChartClose = () => {
    closeChart();
    setOpenChartDialog(null);
  };

  const isDisabled = !useIsRootEditor();

  return (
    <>
      <DropdownButton
        icon="plus"
        label="Insert"
        size="small"
        variant="ghost"
        trackingName="Insert actions"
        disabled={isDisabled}
      >
        <DropdownItem label="Image" onClick={openImage} icon="image" />
        <DropdownItem label="File" onClick={openFile} icon="file" />
        <DropdownItem label="Table" onClick={openTable} icon="table" />
        {hideInsightsControls ? null : (
          <DropdownItem
            label="Chart"
            onClick={async () => setOpenChartDialog(await selectChart(CHART_OPTIONS))}
            icon="chart"
          />
        )}
        <DropdownItem label="HTML snippet" onClick={openSnippet} icon="browser" />
      </DropdownButton>
      {isImageOpen ? <InsertImageDialog siteId={siteId!} onCancel={closeImage} /> : null}
      {isFileOpen ? <InsertFileUploadDialog siteId={siteId!} onCancel={closeFile} /> : null}
      <InsertTableDialog isOpen={isTableOpen} close={closeTable} />
      {openChartDialog === ChartType.InDetail ? <InsertInDetailChartDialog close={onChartClose} /> : null}
      {openChartDialog === ChartType.OverTime ? <InsertOverTimeChartDialog close={onChartClose} /> : null}
      <InsertSnippetModal isOpen={isSnippetOpen} close={closeSnippet} />
    </>
  );
}
